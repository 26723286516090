import React, { useEffect, Fragment, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { track } from "../utils/analytics";
import BtnNext from "../components/BtnNext";
import { EVENT } from "../constants/events.constants";
import ClientStorage from "../utils/client-storage";
import { IMAGE_BASE_URL } from "../constants/urls.constants";

type Props = {
  handleSubmit: Function;
  link_token: string;
  title: string;
  type: string;
  buttonClasses?: string;
  autoPayButtonClasses?: string;
  toggleInstitutionList?: Function;
};

function LinkBankAccountButton(props: Props) {
  const { buttonClasses, autoPayButtonClasses } = props;
  const [submitting, setSubmitting] = useState(false);
  const USE_FINICITY = window["appConfig"].USE_FINICITY === "true";
  useEffect(() => {
    track(EVENT.add_bank);
    if (props.link_token) {
      ClientStorage.setLocal("link_token", props.link_token);
      ClientStorage.setLocal("redirectUrl", window.location.href);
    }
    return () => {};
  }, []);

  function onSuccess(token: any, metadata: any) {
    track(EVENT.onb_bank_connected);
    ClientStorage.setLocal("bank_token", token);
    ClientStorage.setLocal("plaid-metadata", JSON.stringify(metadata));
    props.handleSubmit(token, metadata);
  }

  const config = {
    token: props.link_token,
    onSuccess,
  };

  const { open } = usePlaidLink(config);

  function handleClick() {
    setSubmitting(true);
    if (USE_FINICITY) {
      props.toggleInstitutionList(true);
    } else {
      open();
    }
  }

  let buttonClass = "";
  switch (props.type) {
    case "button":
      buttonClass = `${
        buttonClasses ? buttonClasses : ""
      } bg-white text-black block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full brand-btn-solid`;
      break;
    case "autopay":
      buttonClass = `${
        autoPayButtonClasses ? autoPayButtonClasses : ""
      } bg-white w-80 cursor-pointer text-2xl rounded-3xl ml-2 mr-auto block text-black h-10 pb-10 activeCard`;
      break;
    default:
      buttonClass = `${
        buttonClasses ? buttonClasses : ""
      } bg-white text-black block text-center tracking-tight cursor-pointer ml-auto mr-auto mt-8 rounded-full text-base`;
  }

  return (
    <Fragment>
      <BtnNext
        onClick={handleClick}
        text={props.title}
        className={buttonClass}
      />
      {props.type === "autopay" && (
        <img
          src={`${IMAGE_BASE_URL}/right-arrow.png`}
          alt="right"
          onClick={handleClick}
          className="w-5 h-5 x-sm:mt-1.5 mt-2.5 x-sm:mr-5 mr-2"
        />
      )}
    </Fragment>
  );
}

export default LinkBankAccountButton;
