import { useState } from "react";

import PrimaryButton from "./components/PrimaryButton";
import { IMAGE_PREFIX } from "../../constants/urls.constants";

const FinicityAccounts = ({
  accounts,
  connectMethods,
  showConnectingBankMessage,
  bankConnected,
  onSuccessfulBankConnect,
  onContinue,
}) => {
  const [selectedMethods, setSelectedMethods] = useState([]);
  const { metadata, header, methods } = accounts;
  const { is_selection_allowed } = metadata;

  const onAccountChange = (methodId) => {
    if (
      selectedMethods.length === 0 ||
      !selectedMethods.some((acc) => acc.id === methodId)
    ) {
      setSelectedMethods([
        ...selectedMethods,
        methods.find((acc) => acc.id === methodId),
      ]);
    } else {
      setSelectedMethods(selectedMethods.filter((acc) => acc.id !== methodId));
    }
  };

  if (showConnectingBankMessage) {
    return (
      <div className="flex items-center justify-center p-10 h-full">
        <div className="flex flex-col">
          <img
            // Rahul: Consumer Finicity - File paths
            src={`${IMAGE_PREFIX}/finicity/wait_a_moment.png`}
            alt="Connecting Bank"
            className="w-auto mx-auto"
          />
          <p className="text-t1-headline font-h2 text-center mt-8">
            One moment while we connect your bank
          </p>
        </div>
      </div>
    );
  }

  if (bankConnected) {
    return (
      <div className="flex items-center justify-center p-10 h-full">
        <div className="flex flex-col gap-3">
          <img
            // Rahul: Consumer Finicity - File paths
            src={`${IMAGE_PREFIX}/finicity/bank_connected.png`}
            alt="Connecting Bank"
          />
          <h1 className="text-t1-headline font-h2 text-center">
            Bank Connected
          </h1>
          <p className="text-center text-t1-headline">
            You can now set up this account as a payment method
          </p>
          <PrimaryButton
            className="w-1/2 p-4 self-center cursor-pointer"
            onClick={() => {
              onSuccessfulBankConnect();
            }}
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mb-6">
        <h1 class="font-h2 text-center text-t1-headline p-5">{header.title}</h1>
        <p
          // Rahul: Consumer Finicity - CSS handling due to older tailwind version
          className="text-t1-headline text-center font-b4"
        >
          {header.sub_title}
        </p>
      </div>
      <div className="flex flex-col gap-4 finicity-accounts">
        <div className="rounded-xl bg-white p-6 text-t1-headline">
          {methods.map((method) => {
            return (
              <label
                key={method.id}
                for={method.id}
                className="flex justify-between items-center py-2"
              >
                <div className="flex gap-2">
                  <div className="">
                    <img
                      src={method.thumbnail_url}
                      alt={method.account_type}
                      className="w-8 h-8"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p>{method.account_type}</p>
                    <p>...{method.account_last_4_digits}</p>
                  </div>
                </div>
                {is_selection_allowed && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onAccountChange(method.id);
                    }}
                  >
                    <input
                      id={method.id}
                      checked={selectedMethods.some(
                        (acc) => acc.id === method.id
                      )}
                      className="w-4 h-4"
                      type="checkbox"
                      // Rahul: Consumer Finicity - Commented out
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                    />
                    <span></span>
                  </div>
                )}
              </label>
            );
          })}
        </div>
        <PrimaryButton
          className="w-1/2 p-4 self-center cursor-pointer rounded-full h-12 font-bold"
          disabled={is_selection_allowed ? selectedMethods.length === 0 : false}
          onClick={() => {
            if (is_selection_allowed) {
              connectMethods(selectedMethods);
            } else {
              onContinue();
            }
          }}
        >
          {is_selection_allowed ? "Connect Bank" : "Continue"}
        </PrimaryButton>
      </div>
    </>
  );
};

export default FinicityAccounts;
