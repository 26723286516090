import { PRODUCT_ALIAS_KEY } from "../constants/product-constants";
import { IMAGE_PREFIX } from "../constants/urls.constants";
import ClientStorage from "./client-storage";
import { PARTNER_NAMES_MAP, TENANTS_MAP } from "./constants";
import { getProductIdAliasFromAccessToken, getProductIdAliasFromSearchParam } from "./product-alias";

export const getWhiteLogo = (partnerName, isLight = true) => {
  switch (partnerName) {
    case "krowdfit":
    case "customers_bank":
    case "amc":
    case "blockfi":
    case "ccbank":
    case "slm_ignite":
      return isLight
        ? `${IMAGE_PREFIX}/white_logo.png`
        : `${IMAGE_PREFIX}/logo.png`;
    default:
      return `${IMAGE_PREFIX}/logo.png`;
  }
};

// TODO (1): Sunil-D - WWT-4729 - Need to move all DCP tenant's id in parameter store, 
// as namespace called DCP_TENANT_IDS_MAP. And use those here. It will be more dynamic.
// Example code: 
//export const isDCPTenant = (tenantId) => {
// const tenantIds = Object.values(JSON.parse(DCP_TENANT_IDS_MAP));
//  return tenantIds.includes(tenantId);
//}

export const isDCPTenant = (tenantId) => {
  switch (tenantId) {
    case TENANTS_MAP.ccbank:
    case TENANTS_MAP.ccbank_summit:
    case TENANTS_MAP.dcp:
    case TENANTS_MAP.dcp_edge:
    case TENANTS_MAP.dcp_edge_smb:
    case TENANTS_MAP.deserve:
    case TENANTS_MAP.deserve_edu:
    case TENANTS_MAP.juno:
      return true;
    default:
      return false;
  }
}

export const getAssetsPath = (baseUrl, partnerName) => {
  if (partnerName === PARTNER_NAMES_MAP.ccbank) {
    const product_id_alias = getProductIdAliasFromAccessToken() ?? getProductIdAliasFromSearchParam() ?? ClientStorage.getLocal(PRODUCT_ALIAS_KEY);
    const assetsPath = `${baseUrl}/${partnerName}/${product_id_alias ?? 'default'}`;
    return assetsPath;
  }
  return `${baseUrl}/${partnerName}`;
};

export const enableFreezeCardFeature = (partnerName) => {
  switch (partnerName) {
    case PARTNER_NAMES_MAP.ccbank:
      return false;
    default:
      return true;
  }
}

export function getMonthAndDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.toLocaleString("en-US", { day: "numeric" });
  return  [month, day];
}
