import { IMAGE_PREFIX } from "../../../constants/urls.constants";

const BankNotFound = ({ institutionsMetadata = {} }) => {
  return (
    <div className="flex flex-col items-center justify-evenly h-full">
      <div className="x-sm:w-full sm:w-full md:w-3/4">
        <h1 className="text-t1-headline font-h2 text-center mb-4">Bank Not Found</h1>
        <p className="text-t2-body font-b6 text-center mb-4">
          Please try to search your bank by entering the name manually
        </p>
      </div>
      <img
        className="w-[220px] h-[220px]"
        // Rahul: Consumer Finicity - Due to old tailwind version
        style={{ width: "220px", height: "220px" }}
        // Rahul: Consumer Finicity - Image path
        src={`${IMAGE_PREFIX}/finicity/bank_not_found.png`}
        alt="Bank Not Found"
      />
      <div className="text-t2-body text-center font-b4-bold">
        <p>{institutionsMetadata?.footer_message}</p>
      </div>
    </div>
  );
};

export default BankNotFound;
