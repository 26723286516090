import { MouseEvent, useEffect, useState, useContext } from "react";
import classnames from "classnames";
import {
  IMAGE_PREFIX,
  LOGIN_URL,
  IMAGE_BASE_URL,
  ERROR_PAGE_URL,
  LOGOUT_URL,
  PAYMENT_V3_API_URL,
} from "../constants/urls.constants";
import LoadingIcon from "../components/Loading";
import { numberFormatter } from "../utils/numberFormatter";
import EyeIcon from "../components/EyeIcon";
import EyeCrossIcon from "../components/EyeCrossIcon";
import LogoutIcon from "../components/LogoutIcon";
import ReactHtmlParser from "html-react-parser";
import {
  PAYMENT_METHOD,
  PROFILE_PATH,
  AUTO_PAY,
  ROUTE_CHA,
} from "../constants/route-paths.constants";
import ProfileIcon from "../components/ProfileIcon";
import InfoIcon from "../components/InfoIcon";
import AppTooltip from "../components/AppTooltip";
import { default as ErrorPage } from "./Payment_Error";
import axios, { AxiosResponse } from "axios";
import {track, trackFailure} from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { dashboardConst } from "../constants/dashboard.constants";
import moment from 'moment';
import { clearApplicationToken, getApplicationToken } from "../utils/helpers";
import { FirstCharUpperCase } from "../utils/text";
import Check from '../components/Check';
import { AccountDataContext } from "../components/AccountDataContext";
import { commonHeaders } from "../utils/client";
import { getWhiteLogo, isDCPTenant } from "../utils/utils";

type Props = { partnerName: String };

const hintText = `Oops! We couldn't retrieve your card details at the moment.
 Please ensure your card is active and try again.`;

const EyeIconColor = {
  deserve: "#FFFFFF",
  deserve_edu: "#FFFFFF",
  earnest: "#FFFFFF",
  sw: "#112949",
  krowdfit: '#FFFFFF',
  blockfi: '#FFFFFF',
  customers_bank: '#FFFFFF',
  amc: "#FFFFFF",
  slm_ignite: "#FFFFFF",
  ccbank: "#FFFFFF"
};

const ProfileIconColor = {
  deserve: "#FFFFFF",
  deserve_edu: "#FFFFFF",
  blockfi: "#FFFFFF",
  earnest: "#FFFFFF",
  sw: "#112949",
  krowdfit: '#FFFFFF',
  customers_bank: '#FFFFFF',
  amc: "#FFFFFF",
  slm_ignite: "#FFFFFF",
  ccbank: "#FFFFFF"
};

function Dashboard(props: Props) {
  const { accountData = {}, accountDataErr, accountDataErrObj } = useContext(AccountDataContext) || {};
  const [Data, setData] = useState(null);
  const [HasError, setHasError] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [BlurNumbers, setBlurNumbers] = useState(false);
  const [logoutFailed, setLogoutFailed] = useState(false);
  const toolTipMessage = `Manage your rewards in the mobile app.`;
  const _window = window as any;

  useEffect(() => {

    const fetchData = async () => {
      track(EVENT.servicing_dashboard_screen_view_initiated);
      if(!Data && Object.keys(accountData).length > 0){
        track(EVENT.servicing_dashboard_screen_viewed);
        const applicationToken =  getApplicationToken();
        const headers = { Authorization: `Bearer ${applicationToken}`,  ...commonHeaders() };
        axios.get(PAYMENT_V3_API_URL, {headers}).then((payment_data) => {
          setData({
            ...accountData,
            ...payment_data.data
          });
          setLoading(false);
        })

      }
  
      if(accountDataErr){
        if (accountDataErrObj.response) {
          trackFailure(EVENT.servicing_dashboard_screen_view_failed, accountDataErrObj);
          if (accountDataErrObj.response.status === 400 || accountDataErrObj.response.status === 404) {
            window.location.assign(ERROR_PAGE_URL);
          } else if (accountDataErrObj.response.status === 401) {
            window.location.assign(LOGIN_URL);
          } else {
            setHasError(true);
          }
        }
      }
    }

    fetchData();

  }, [accountData, accountDataErr, accountDataErrObj, Data]);

  function ToggleBlur() {
    setBlurNumbers((e) => !e);
  }

  if (HasError) {
    return (
      <ErrorPage
        makePayment={() => window.location.reload()}
        partnerName={props.partnerName}
        type="dashboard"
      />
    );
  }

  if (Loading) {
    return <LoadingIcon />;
  }

  const credit_line = Data ? Data.credit_line : {};
  const card = Data && Data.cards ? Data.cards[0] : Data.virtual_cards ? Data.virtual_cards[0] : {last_4_digits: '-'};
  const payment_detail_metadata = Data ? Data.payment_detail?.metadata : {};
  const payment_home_card = Data ? Data.payment_home_card : {};
  const { action1 = {}, action2 = {}, summary = {}, title = {} } = payment_home_card || {};
  const statement_balance = credit_line?.statement_balance;
  const available_credit = credit_line?.available_credit;
  const rewards = credit_line?.rewards;
  const minimum_payment = credit_line?.minimum_payment;
  const due_amount = credit_line?.due_amount;
  const card_balance: number = parseFloat(`${credit_line?.display_current_balance?.amount?.whole_number?.text}.${credit_line?.display_current_balance?.amount?.fraction?.text}`) || 0;
  const due_date =payment_detail_metadata.due_date_utc ? moment(payment_detail_metadata.due_date_utc).format('MMM DD, YYYY') : dashboardConst.noDueDate;
  const your_rewards = Data?.crypto_rewards?.your_rewards ?? "";

  const payment_status = payment_detail_metadata?.payment_status;
  const logo_image = getWhiteLogo(props.partnerName);

  const due_amount_str =
    Number(minimum_payment) === 0 ? (
      <span>
        Nothing to pay
      </span>
    ) : (
      numberFormatter(minimum_payment)
    );

  function unFormattedIncome(newValue="$0") {
    return parseFloat(newValue.toString().replace('$', '').replace(/,/g, ''));
  }

  const logout = () => {
    axios.get(LOGOUT_URL)
      .then((resp: AxiosResponse | any) => {
        setLogoutFailed(false);
        clearApplicationToken();
        window.location.href = LOGIN_URL;
      })
      .catch(() => {
        setLogoutFailed(true);
      });
  };

  const profileNav = () => {
    track(EVENT.homeProfile);
    window.location.href = window.location.origin + PROFILE_PATH;
  };

  const onReactivation = (evt: MouseEvent<HTMLButtonElement>) => {
    track(EVENT.onb_card_reactivation_button_clicked);
    window.location.href = ROUTE_CHA;
  };

  function formatMultiText(str) {
    let _str = String(str).toLocaleLowerCase().split(" ");
    return _str.map(s => FirstCharUpperCase(s)).join(" ");
  }

  const cards_to_show = Data?.cards || Data?.virtual_cards || [];

  const cardBalanceTitle = isDCPTenant(_window?.appConfig?.PROGRAM_ID) ? 'Balance' : `Balance for card (*${card.last_4_digits})`;

  return (
    <div className={`dashboard-${props.partnerName} flex x-sm:block`}>
      <div className={`flex flex-col w-full lg:w-3/4 lg:h-screen pl-14 pb-8 pt-4 md:pt-10 lg:pt-12 x-sm:pl-5 x-sm:pr-5 md:pr-5 x-sm:pt-4 relative`}>
        <div
          data-testid="logo"
          className={`mb-20 dashboard-margin-${props.partnerName} x-sm:mb-4 flex flex-row items-center`}
        >
          <img className="x-sm:w-24 w-28" src={logo_image} alt="logo" />
          <div className="flex w-full justify-end">
            <span
              className="btn-blur-toggle mr-2.5 x-sm:mr-2 x-sm:p-1 rounded-full"
              onClick={ToggleBlur}
            >
              {BlurNumbers ? (
                <span title="Show Amount">
                  <EyeIcon
                    fillColor={EyeIconColor[String(props.partnerName)]}
                  />
                </span>
              ) : (
                <span title="Hide Amount">
                  <EyeCrossIcon
                    fillColor={EyeIconColor[String(props.partnerName)]}
                  />
                </span>
              )}
            </span>
            <span
              id="logout"
              className="btn-blur-toggle rounded-full mr-2.5 x-sm:mr-2 x-sm:p-1"
              data-testid="logoutButton"
              onClick={logout}
              title="Log Out"
            >
              <LogoutIcon fillColor={EyeIconColor[String(props.partnerName)]} />
            </span>
            <span
              id="profileButton"
              className="btn-blur-toggle rounded-full x-sm:mr-2 x-sm:p-1"
              data-testid="profileButton"
              onClick={profileNav}
              title="Profile Button"
            >
              <ProfileIcon
                fillColor={ProfileIconColor[String(props.partnerName)]}
              />
            </span>
          </div>

        </div>

        {props.partnerName == "blockfi" && <div className="mb-20 mt-5 md:mr-14 x-sm:text-xs x-sm:mb-8">
          Notice to BlockFi Cardholders – 11/28/2022 <br />
          In light of recent developments at BlockFi, which are beyond our control, purchasing privileges on BlockFi Rewards Visa® Signature Cards are suspended until further notice. As a result, you will not be able to make purchases using your card. Please don’t hesitate to call 833.426.0304 or 866.480.3637 or email BlockFicard@deserve.com or refer to the contact information provided on the back of your card with any questions. For more information, please refer to the FAQs at deserve.com/blockfi
        </div>}

        {props.partnerName !== "blockfi" && <div className="mt-14"/>}

        {
          (<><div className="grid grid-cols-3 mb-8">
            <div className="md:w-56 x-sm:w-30">
              <div className="uppercase text-xs-10 leading-5 text-caption-6 x-sm:w-16 x-sm:leading-4">
                {dashboardConst.statement} {dashboardConst.balance}
              </div>
              <div
                data-testid="statement_bal"
                className={classnames(
                  "transition duration-1000 text-base font-bold text-heading-5 lg:text-heading-3",
                  { "filter blur": BlurNumbers }
                )}
              >
                {numberFormatter(statement_balance)}
              </div>
            </div>
            <div className="md:w-56 x-sm:w-40">
              <div className="uppercase text-xs-10 leading-5 text-caption-6 x-sm:w-16 x-sm:leading-4">
                {dashboardConst.available} {dashboardConst.credit}
              </div>
              <div
                className={classnames("text-base transition duration-1000 text-heading-5", {
                  "filter blur": BlurNumbers,
                })}
              >
                {numberFormatter(available_credit)}
              </div>
            </div>
            {rewards && rewards.cleared_balance ? (
              <div className="md:w-40 x-sm:w-24">
                <AppTooltip text={toolTipMessage}>
                  <div className="text-xs-10 uppercase leading-5 text-caption-6 x-sm:leading-4">
                    {dashboardConst.available} {dashboardConst.rewards}
                  </div>
                </AppTooltip>
                <div
                  className={classnames(
                    "text-base font-bold transition duration-1000 text-heading-5",
                    { "filter blur": BlurNumbers }
                  )}
                >
                  {rewards.cleared_balance
                    ? numberFormatter(rewards.cleared_balance)
                    : "-"}
                </div>
              </div>
            ) : null}
          </div>
            <div className="grid grid-cols-3 x-sm:mb-20 md:mb-14">
              <div className="md:w-56 x-sm:w-30">
                <div className="uppercase text-xs-10 leading-5 text-caption-6">
                  {dashboardConst.minimum} {dashboardConst.due}
                </div>
                <div
                  data-testid="minimum_payment"
                  className={classnames(
                    "text-base font-bold transition duration-1000 text-heading-5 x-sm:w-16",
                    { "filter blur": BlurNumbers }
                  )}
                >
                  {due_amount_str}
                </div>
              </div>
              <div className="md:w-56 x-sm:w-40">
                <div className="uppercase text-xs-10 leading-5 text-caption-6">{dashboardConst.due}
                </div>
                <div
                  data-testid="due_amount"
                  className={classnames(
                    "text-base font-bold transition duration-1000 text-heading-5",
                    { "filter blur": BlurNumbers }
                  )}
                >
                  {due_date}
                </div>
              </div>
            </div></>)}
        <div>
          <div className="uppercase text-xs-10 leading-5 text-caption-6 mb-5">
            {card && cardBalanceTitle}
            {(!cards_to_show || cards_to_show.length === 0)  && (
              <button className="hint--bottom-right hint--rounded hint--medium" aria-label={hintText}>
                <InfoIcon />
              </button>
            )}
          </div>
          <div className="flex justify-between x-sm:flex-col">
            <div
              className={classnames(
                `dfc-dashboard-balance text-6_5xl font-bold transition duration-1000 dashboard-t4 x-sm:w-full x-sm:text-4xl x-sm:mb-1.5 x-sm:mt-1.5 leading-[60px] ${props.partnerName === 'slm_ignite' ? 'font-din-pro' : ''} text-number-1`,
                { "filter blur-2xl": BlurNumbers },

              )}
            >
              {numberFormatter(card_balance)}
            </div>
            <div className="flex x-sm:mt-20  x-sm:w-full lg:mr-16 x-sm:flex-row-reverse x-sm:justify-between nodue-text">
              {Data?.payment_home_card && <div className="lg:mr-4 w-44 pl-2 text-b5 nodue-text">
                  <p className="">{ReactHtmlParser(title.text)}</p>
                  <br/>
                  <p>{summary.text}</p>
              </div>}
              <div>
                <button
                  className={`x-sm:w-40 w-60 border-solid border md:mb-4 x-sm:mt-0 sm:mt-0 x-sm:mb-4 sm:mb-4 text-white cursor-pointer rounded-full px-2 py-3 justify-center btn-autopay-${props.partnerName} btn-3`}
                  onClick={() => window.location.assign(AUTO_PAY)}
                  id="autopayButton"
              >
                {Data?.payment_home_card?.is_migration_in_progress
                  ? dashboardConst.btnAutoPay
                  : formatMultiText(action2?.title?.text || "Autopay")
                }
              </button>
              <br />
              {(action1?.title?.text)
                    ? (<button
                    className={
                      `x-sm:w-40 w-60 bg-white focus:outline-none text-black rounded-3xl px-2 py-4 justify-center btn-pay-${props.partnerName} btn-3`
                    }
                    onClick={() => {
                      track(EVENT.payClicked);
                      window.location.assign(PAYMENT_METHOD)
                    }}
                  >
                    {Data?.payment_home_card?.is_migration_in_progress
                      ? dashboardConst.btnAutoPay
                      : formatMultiText(action1?.title?.text)
                    }
                  </button>)
                  : (<button
                        className={`font-bold x-sm:w-40 w-60 bg-white focus:outline-none text-black rounded-3xl px-2 py-3 justify-center btn-pay-${props.partnerName} btn-3`}
                        onClick={() => {
                          track(EVENT.payClicked);
                          window.location.assign(PAYMENT_METHOD);
                        }}
                      >
                        <div className="flex justify-center items-center">
                          {Data?.payment_home_card?.is_migration_in_progress
                            ? dashboardConst.btnPay
                            : Data?.payment_home_card ? <Check /> : formatMultiText(dashboardConst.btnPay)}
                        </div>
                      </button>)
                  }
              </div>
            </div>
          </div>
        </div>

        {props.partnerName !== "blockfi" && (<div className="flex flex-row justify-between md:pr-8 md:mt-10 x-sm:mt-5 lg:mt-auto 2xl:mt-auto items-center">
        {props.partnerName === "deserve_edu" && (
          <img
            className="lg:inline-block img-powered-by"
            src={`${IMAGE_BASE_URL}/deserve_edu/powered_by.png`}
            alt="powered by deserve"
          />
        )}
          <div className="opacity-60"> </div>
          <img
            className="inline-block h-10 x-sm:mr-0 md:mr-0 lg:mr-14"
            src={`${IMAGE_BASE_URL}/${props.partnerName === "amc" ? 'visa' : 'mastercard'}.png`}
            alt="powered by deserve"
          />
        </div>)}

        {props.partnerName === "blockfi" && (<div className="flex flex-row justify-between pr-8 x-sm:absolute x-sm:right-0 x-sm:bottom-7 md:mt-auto lg:mt-auto 2xl:mt-auto">
          <div className="opacity-60"> </div>
          <img
            className="inline-block h-12 mr-14 x-sm:mr-0"
            src={`${IMAGE_PREFIX}/evolve-white-logo.png`}
            alt="powered by deserve"
          />
        </div>)}
      </div>
    </div>
  );
}

export default Dashboard;
