import { IMAGE_PREFIX } from "../../../constants/urls.constants";

const InstitutionsError = ({ type }) => {
  if (type === "DEFAULT_BANK_ERROR") {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-t1-headline font-h2 text-center mb-4">
          Search by entering your institution name
        </h1>
        <img
          className="w-[200px]"
          // Rahul: Consumer Finicity - Due to old tailwind version
          style={{ width: "200px" }}
          // Rahul: Consumer Finicity - File paths
          src={`${IMAGE_PREFIX}/finicity/search_and_select_bank.png`}
          alt="Error in loading default institutions"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-t1-headline font-h2">Yikes, something went wrong. Let's try again.</h1>
      <img
        className="w-[200px]"
        // Rahul: Consumer Finicity - Due to old tailwind version
        style={{ width: "200px" }}
        // Rahul: Consumer Finicity - File paths
        src={`${IMAGE_PREFIX}/finicity/bank_api_or_network_error.png`}
        alt="Error in filtering institutions"
      />
    </div>
  );
};

export default InstitutionsError;
