import React, {useRef, useEffect} from "react";
import emitter from "../utils/emitter";
import {IMAGE_PREFIX} from "../constants/urls.constants";
import ReactHtmlParser from "html-react-parser";

type Props = { CurrentStep: { ui_template_type: string }; handleSubmit: Function; breadcrumbs: Object; event: string };

function TemplateH(props: Props) {
  const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const title = ModelData.default_state.title;
  const FormRef = useRef(null);
  let partnerName = window['appConfig'].PARTNER_NAME;

  useEffect(() => {
    emitter.emit("progress", {breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep["identifier"]});
  }, [props.CurrentStep, props.breadcrumbs]);


  function handleSubmit(e) {
    e.preventDefault();
    props.handleSubmit({})
  }

  return (
    <div key={props.CurrentStep["step_info"].step_identifier} id={props.CurrentStep["step_info"].step_identifier}
         className="grid grid-cols-3 mb-10 x-sm:grid-cols-2">
      <div className="col-span-2">
        <p className="text-main x-sm:mb-10 mb-10 x-sm:text-center" data-testid="title">{title}</p>
        <div className="pb-5 ">
          <form ref={FormRef} noValidate onSubmit={handleSubmit}>
            <div>
              {ModelData.template_h_rows.map((row, index) => {
                let imageName = `${IMAGE_PREFIX}/${row["left_container"].left_image.name}`;
                return <label className="grid grid-cols-4 mb-5">
                  <div
                    className="radio-select-index border rounded-full roundedBackground  mr-2 ">
                    <img className={index === 1 ? "ml-0.5" : ""} width={index === 1 ? "20px" : "26px"} height="26px"
                         src={`${imageName}.png`}/>
                  </div>
                  <div className="text-t2 pt-3 col-span-3 x-sm:w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    {row["right_container"].title.text}
                    { row["right_container"].subtitle && <div className="get-started-subtitle">
                      {ReactHtmlParser(row["right_container"].subtitle.text)}
                    </div> }
                  </div>
                </label>
              })}
            </div>
          </form>
        </div>
        <img width={"120px"} height={"240px"} className="mx-auto mb-6 md:hidden"
           src={`${IMAGE_PREFIX}/MobileApp.png`}/>
        <button className={`consent-button-submit rounded-full py-3 px-24 text-white x-sm:w-full`}
                  onClick={handleSubmit}>Get Started</button>
      </div>
      <img width={"223px"} height="478px" className="ml-5 x-sm:hidden"
           src={`${IMAGE_PREFIX}/MobileApp.png`}/>
    </div>
  )
}

export default TemplateH;
