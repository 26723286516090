import { CC_BANK_PRODUCTS, DEFAULT_PRODUCT, PRODUCT_ALIAS_KEY } from "../constants/product-constants";
import ClientStorage from './client-storage';
import decode from 'jwt-decode';

export const getProductIdAliasFromAccessToken = () => {

    const token = ClientStorage.getLocal('application_token');

    if(!token || token === 'undefined') {
        return null;
    }

    const product_id_alias = (decode(token) as any).product_id_alias;

    return product_id_alias;

}

export const getProductIdAliasFromSearchParam = () => {
    const search = new URLSearchParams(document.location.search);
    const product_alias = search.get(PRODUCT_ALIAS_KEY);
    return product_alias;
}

export const setProductIdAliasFromURL = (partnerName: string) => {
    if(partnerName === 'ccbank') {
        const product_id_alias_from_url = getProductIdAliasFromSearchParam();
        if(product_id_alias_from_url && CC_BANK_PRODUCTS.includes(product_id_alias_from_url.toLowerCase())) {
          ClientStorage.setLocal(PRODUCT_ALIAS_KEY, product_id_alias_from_url.toLowerCase())
        } else if (!CC_BANK_PRODUCTS.includes(ClientStorage.getLocal(PRODUCT_ALIAS_KEY)?.toLowerCase())) {
          // i.e. no product_alias query param present
          /**
           * Handle the scenario - User is coming from a url where product alias is missing (default url)
           * Check local storage value is not Peak or Warrior then write to Default. In other words If local storage value is null then write default
           */
          ClientStorage.setLocal(PRODUCT_ALIAS_KEY, DEFAULT_PRODUCT);
        }
    } else {
        ClientStorage.setLocal(PRODUCT_ALIAS_KEY, DEFAULT_PRODUCT);
    }
}

export const getProductIdAliasInLocalStorage = () => {
    let productIdAliasInLocalStorage = null;
    
    if(ClientStorage.getLocal('product_id_alias')) {
        productIdAliasInLocalStorage = ClientStorage.getLocal('product_id_alias');
    }
    
    return productIdAliasInLocalStorage;
}

export const setProductIdAlias = () => {
    const productIdAliasInLocalStorage = getProductIdAliasInLocalStorage();
    const productIdAliasInToken = getProductIdAliasFromAccessToken();

    if(CC_BANK_PRODUCTS.includes(productIdAliasInLocalStorage) && (productIdAliasInLocalStorage === productIdAliasInToken)) {
        return;
    }

    if(productIdAliasInToken) {
        ClientStorage.setLocal(PRODUCT_ALIAS_KEY, productIdAliasInToken)
    } else {
        setProductIdAliasFromURL(window["appConfig"].PARTNER_NAME);
    }
}
