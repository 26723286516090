import { IMAGE_PREFIX } from "../../../constants/urls.constants";
import PrimaryButton from "./PrimaryButton";

// Rahul: Consumer Finicity - Added this new error component
const AccountConnectionError = ({ type }) => {
  return (
    <div className="flex flex-col items-center justify-center pt-6">
      <h1 className="text-t1-headline font-h2 text-center mt-8">
        Yikes, something went wrong. Let's try again.
      </h1>
      <img
        className="max-w-xs pt-4 mb-8"
        src={`${IMAGE_PREFIX}/finicity/bank_api_or_network_error.png`}
        alt="Error in filtering institutions"
      />
      {/* // TODO (1): Sunil-D - WWT-XXXX [UI] [Consumer] Clear what should happen on click of Try Again button, should it redirect to previous page or reload the page. */}
      <PrimaryButton
        className="w-1/2 p-4 self-center cursor-pointer rounded-full h-12 font-bold"
        onClick={() => { }}
      >
        Try Again
      </PrimaryButton>
    </div>
  );
};

export default AccountConnectionError;
